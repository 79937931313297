import gql from 'graphql-tag';
import { currentAppUser } from '@manakin/app-core/graphQlHelpers';

export const CURRENT_USER = gql`
    query currentAppUser {
        currentAppUser {
            ${currentAppUser}
        }
    }
`;


import logo from '../assets/logo.svg';

export default async () =>
	new Promise(async (resolve) => {
		const config = {
			whiteLabel: 'CBD Nascholing',
			defaultLang: 'nl_NL',
			files: [
				{
					id: 'testfile',
					file: logo,
				},
			],
			pages: {
				appLogin: {
					showHeaderTitle: true,
					headerTitleIsHtml: false,
					showFootNote: false,
					visualTitle: true,
					footerLogo: false,
				},
				appRegistration: {
					showBirthDate: true,
					showPhoneNumber: true,
					showRegisterType: false,
					showRegisterCode: false,
					registerCodeRequired: false,
					addMoreLicenses: true,
				},
				appNoLicense: {
					showFootNote: false,
				},
				appAccount: {
					showEducation: true,
					hideSchool: true,
					switchFunction: true,
					hideAddLicense: true,
					showClaimedOn: false,
					hideDelete: true,
					showAddProduct: true,
					showInitials: true,
					showNewsLetter: true,
				},
				boxCard: {
					showThemeIconsInList: true,
					showDescription: true,
				},
				dashboard: {
					listViewOnly: false,
					filter: true,
					hideTeacher: true,
				},
				showDashboard: true,
				appElementOverlay: {
					showHeaderTitle: true,
				},
				appFactCard: {
					hideInstruction: true,
				},
				general: {
					showNews: true,
					showResults: true, //CBD nascholing is the only whitelabel with this configuration, Adjust the hardcoded Program ID in the app-account/Results component if this ever changes.
					showInvoices: true
				},
				rapportage: {
					hideProgress: true,
				},
			},
			general: {
				showProgramDropdown: true,
				showLogo: true,
				showYears: true,
				showFilters: true,
				showTags: true,
				useBoxResults: true,
				sso: false,
				cbd: true,
				certificate: true,
			},
			search: {
				news: true,
				boxes: true,
				elements: true,
				factCards: true,
			},
			getFile: (id) => config.files.find((file) => file.id === id),
			getPage: (key) => config[key],
		};

		resolve(config);
	});

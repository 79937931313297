import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import { beautySecondary, black, white, mountain } from '../colors';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.png';
import accountBackgroundImage from '../../assets/account-background.jpg';
import logo from '../../assets/logo.png';
import pdfIcon from '../../assets/pdf.svg';
import loginVisual from '../../assets/login-visual.svg';
import footerLogo from '../../assets/footer-logo.svg';
import Cookies from 'js-cookie';
import infoIcon from '../../assets/info.svg';
import headerText from '../../assets/header-text.png';

// Themes
import stomachTheme from '../../assets/themes/stomach.svg';
import noseTheme from '../../assets/themes/nose.svg';
import generalTheme from '../../assets/themes/algemeen.svg';
import campagneTheme from '../../assets/themes/campagne.svg';
import skinTheme from '../../assets/themes/huid.svg';
import maagdarmTheme from '../../assets/themes/maagdarm.svg';
import restTheme from '../../assets/themes/overige.svg';
import painTheme from '../../assets/themes/pijnkoorts.svg';
import toezichtTheme from '../../assets/themes/toezicht.svg';
import coldTheme from '../../assets/themes/verkoudheid.svg';
import brainTheme from '../../assets/themes/instaptoets.svg';

const themeIcons = {
	stomach: { backgroundImage: `url(${stomachTheme})` },
	nose: { backgroundImage: `url(${noseTheme})` },
	algemeen: { backgroundImage: `url(${generalTheme})` },
	campagne: { backgroundImage: `url(${campagneTheme})` },
	huid: { backgroundImage: `url(${skinTheme})` },
	maagdarm: { backgroundImage: `url(${maagdarmTheme})` },
	overige: { backgroundImage: `url(${restTheme})` },
	pijnkoorts: { backgroundImage: `url(${painTheme})` },
	toezicht: { backgroundImage: `url(${toezichtTheme})` },
	verkoudheid: { backgroundImage: `url(${coldTheme})` },
	brains: { backgroundImage: `url(${brainTheme})` },
};

const yellow = createColor('#c9e7e9');
const green = createColor('#3e9618');

const _primary = Cookies.get('primary') || '#3F6BA3';
const _secondary = Cookies.get('secondary') || '#00C597';
const _tertiary = Cookies.get('tertiary') || white[500];
const _palette = Cookies.get('palette') || '#3F6BA3';

const theme = (obj = {}) => {
	const primaryButtonBackgroundColor = obj.primaryButtonBackgroundColor;
	const primaryButtonHoverBackgroundColor = obj.primaryButtonHoverBackgroundColor;
	const primaryButtonBorderColor = obj.primaryButtonBorderColor;
	const primaryButtonHoverBorderColor = obj.primaryButtonHoverBorderColor;
	const secondaryButtonBackgroundColor = obj.secondaryButtonBackgroundColor;
	const secondaryButtonHoverBackgroundColor = obj.secondaryButtonHoverBackgroundColor;
	const secondaryButtonBorderColor = obj.secondaryButtonBorderColor;
	const secondaryButtonHoverBorderColor = obj.secondaryButtonHoverBorderColor;
	const primaryFontColor = obj.primaryFontColor;
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};
	_obj.primaryButtonBackgroundColor = obj.primaryButtonBackgroundColor;
	_obj.primaryBackgroundColor = obj.primaryBackgroundColor;
	_obj._primary = _primary;
	_obj._secondary = obj.themeColor || _secondary;
	_obj._tertiary = _tertiary;
	_obj._palette = _palette;
	_obj.indication = obj.indicationColor || '#3F6BA3';
	_obj._primaryWorkform = _primary;
	_obj._secondaryWorkform = _secondary;
	_obj.buttonRadius = obj.buttonBorderRadius || '0px';
	_obj.defaultBoxColor = white;

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _palette;
	_obj.defaultElement = obj.defaultWorkformColor || obj.defaultElementColor || _obj._secondary;
	_obj.primaryElement = obj.primaryElementColor || obj.primaryWorkformColor || _obj._secondary;
	_obj.secondaryElement = obj.defaultWorkformColor || obj.secondaryElementColor || _obj._primary;
	_obj.defaultWorkform = obj.defaultWorkformColor || _obj._secondary;
	_obj.primaryWorkform = obj.primaryWorkformColor || obj.primaryElementColor || _obj._primary;
	_obj.secondaryWorkform = obj.secondaryWorkformColor || obj.secondaryElementColor || _obj._secondaryWorkform;

	//Dashboard
	_obj.dashboardContentColor = createColor(_palette);
	_obj.dashboardTitleColor = createColor(primaryFontColor || _palette);
	_obj.dashboardResumeButton = createColor(obj.themeColor || _primary);
	_obj.dashboardBackgroundColor = createColor(_obj._secondary);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);
	_obj.mountainColor = createColor(mountain);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = _obj.createdPalette;
	_obj.secondaryPaletteColor = _obj.secondaryColor;

	//type colors
	_obj.typeColor = _obj._tertiary;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = _obj.createdPalette;

	//box backgroundColors
	_obj.defaultBoxBGColor = _obj.createdPalette;
	_obj.boxBGColor = createColor(_obj.primaryBackgroundColor);

	//box name colors
	_obj.defaultBoxColor = createColor(_obj.defaultBox);
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = _obj.createdPalette;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;

	//backgroundColors
	_obj.defaultBackgroundColor = white;
	_obj.primaryFontColor = createColor(primaryFontColor || _obj._palette);
	_obj.defaultTitleColor = createColor(_obj._palette);
	_obj.defaultContentColor = createColor('#4E5666');
	_obj.secondaryBackgroundColor = createColor('#F2F2F2');

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = beautySecondary;
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = _obj.primaryPaletteColor;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.primaryPaletteColor;
	_obj.defaultContentHover = _obj.secondaryColor;
	_obj.LessonButtonColor = createColor(obj.themeColor || _obj._palette);
	_obj.defaultBorderHoverColor = _obj.secondaryColor;

	//BUTTONS
	_obj.homeButtonBGColor = createColor(primaryButtonBackgroundColor || obj.themeColor || _obj._palette);
	_obj.homeButtonBGColorHover = createColor(primaryButtonHoverBackgroundColor || _obj.homeButtonBGColor[700]);
	_obj.homeButtonBorderColor = createColor(primaryButtonBorderColor || _obj.homeButtonBGColor[700]);
	_obj.homeButtonBorderColorHover = createColor(primaryButtonHoverBorderColor || _obj.homeButtonBGColorHover[700]);

	_obj.buttonBGColor = createColor(obj.themeColor || _obj._palette);
	_obj.buttonBGColorHover = createColor(_obj.buttonBGColor[700]);
	_obj.buttonBorderColor = createColor(_obj.buttonBGColor[700]);
	_obj.buttonBorderColorHover = createColor(_obj.buttonBGColorHover[700]);

	//SECONDARY
	_obj.homeSecondarybuttonBGColor = createColor(secondaryButtonBackgroundColor || '#FFF');
	_obj.homeSecondarybuttonBGColorHover = createColor(secondaryButtonHoverBackgroundColor || _obj.homeSecondarybuttonBGColor[700]);
	_obj.homeSecondarybuttonBorderColor = createColor(secondaryButtonBorderColor || _obj.primaryPaletteColor[500]);
	_obj.homeSecondarybuttonBorderColorHover = createColor(secondaryButtonHoverBorderColor || _obj.primaryPaletteColor[700]);

	//rest
	_obj.defaultBorderColor = createColor('#E6E6E6');
	_obj.correctAnswer = createColor('#B9E1DC');
	_obj.loginBackground = createColor('#F5F5F5');
	_obj.softBorderColor = createColor('#EFEFEF');
	_obj.subHeaderColor = createColor('#8A8A8A');
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1200px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '700px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint = '@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint = '@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = "'Montserrat', sans-serif;";
	_obj.secondaryTitleFont = "'Montserrat', sans-serif";
	_obj.workformQuestionFont = "'Montserrat', sans-serif";
	_obj.defaultAnswerFont = "'Montserrat', sans-serif";
	_obj.defaultContentFont = "'Montserrat', sans-serif";

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultContentFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 800,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundImage: `url(${footerLogo})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '3rem',
		bottom: '3rem',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.listStyle = {
		'& ol, & ul': {
			padding: 0,
			counterReset: 'li-index',
			listStyle: 'none',
		},
		'& li': {
			position: 'relative',
			paddingLeft: '2.75rem',
		},
		'& li::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: 0,
		},
		'& ul li::before': {
			top: '12px',
			width: '8px',
			height: '8px',
			background: 'currentColor',
			borderRadius: '50%',
		},
		'& ol li::before': {
			counterIncrement: 'li-index',
			content: 'counter(li-index)',
		},
	};

	_obj.breakpoints = createBreakpoints({});

	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Montserrat',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: props.secondaryColor[200],
				main: props.secondaryColor[400],
				dark: props.secondaryColor[600],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[300],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: amber[400],
				main: amber[600],
				dark: amber[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: green,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			footerLogo: footerLogo || logo,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackground: props.loginBackground,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			pdfIcon: pdfIcon,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			softBorderColor: props.softBorderColor,
			subHeaderColor: props.subHeaderColor,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			themeIcons: {
				...themeIcons,
			},
			typeColor: props.typeColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			workformTitleFont: props.workformTitleFont,
		}),
		overrides: {
			AccordionFaq: {
				panelRoot: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
			},
			AppAccountNewsItem: {
				fontStyle: {
					color: 'white',
				},
			},
			AppAccountWrapper: {
				root: {
					background: props.secondaryBackgroundColor[500],
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppBar: {
				dropdownInput: {
					fontSize: '1.4rem',
					letterSpacing: '.3rem',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
				},
				logo: {
					backgroundImage: `url(${logo})`,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					width: '11rem',
					height: '7rem',
					[props.breakpoints.up('md')]: {
						width: '13rem',
						height: '9rem',
					},
				},
				programsDropdownRoot: {
					[props.breakpoints.down('sm')]: {
						left: '25px',
						right: '25px',
						width: 'auto',
						marginTop: '120px',
						transform: 'none',
					},
				},
			},
			appBestTimes: {
				user: {
					backgroundColor: props.primaryColor[500],
				},
				currentUser: {
					backgroundColor: props.primaryPaletteColor[500],
					'& $body': {
						color: 'white',
					},
					'& $time': {
						color: 'white',
					},
				},
			},
			AppBox: {
				footerLogo: {
					...props.footerLogoStyle,
					right: '12rem',
					bottom: '-39px',
				},
			},
			AppBoxHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
					padding: '8rem 0 30rem',
					[props.breakpoints.up('md')]: {
						padding: '18rem 0 60rem',
					},
				},
				wrapper: {
					[props.breakpoints.up('md')]: {
						maxWidth: '1100px',
					},
				},
				body: {
					color: props.primaryColor[500],
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						fontWeight: '500',
						lineHeight: '4rem',
						color: props.primaryColor[500],
					},
				},
			},
			AppBoxCard: {
				boxRoot: {
					borderRadius: props.defaultButtonRadius,
				},
				withOverflow: {
					borderRadius: props.defaultButtonRadius,
				},
				backgroundImage: {
					transform: 'scale(1.1)',
				},
				percentage: {
					fontWeight: 800,
				},
				inner: {
					padding: '2.25rem',
					[props.breakpoints.up('md')]: {
						height: '40rem',
						padding: '0 6rem',
						'&:hover': {
							'& $backgroundImage': {
								transform: 'translateX(2%) scale(1.1)',
							},
							'& $overflow': {
								transform: 'scale(1) translate(2%, -20px)',
							},
							'& $boxDescription': {
								visibility: 'visible',
								transform: 'none',
								opacity: 1,
							},
							'& $boxSubtitle': {
								display: 'none',
							},
							'& $boxName': {
								opacity: 0,
								visibility: 'hidden',
								transform: 'translateY(-32px)',
							},
							'& $boxCardLabel': {
								transform: 'none',
							},
						},
					},
				},
				innerDone: {
					[props.breakpoints.up('md')]: {
						height: '30rem',
					},
				},
				boxName: {
					fontWeight: '600',
					letterSpacing: 0,
					transition: 'all .2s ease',
					fontSize: '3rem',
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						maxWidth: '50%',
					},
				},
				boxDescription: {
					position: 'absolute',
					marginTop: 0,
					visiblity: 'hidden',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'all .2s ease',
					fontSize: '16.5px'
				},
				boxCardText: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
					},
				},
				listView: {
					'& $boxName': {
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
				},
				boxRootOverflow: {},
				listViewRoot: {
					'&$boxRootOverflow': {
						marginTop: 0,
					},
					'& $withOverflow': {
						overflow: 'hidden',
					},
					'& $overflow': {
						height: '100%',
						transform: 'scale(1.1)',
					},
					'& $withOverflow:hover': {
						'& $backgroundImage': {
							transform: 'translateX(2%) scale(1.1)',
						},
					},
				},
			},
			AppBoxes: {
				wrapper: {
					maxWidth: '1250px',
				},
				smallWrapper: {
					backgroundColor: '#fafafa',
				},
			},
			AppBoxLayout: {
				normalWrapper: {
					[props.breakpoints.up('md')]: {
						marginTop: '0px',
					},
				},
				root: {
					position: 'relative',
					paddingBottom: '10rem',
					[props.breakpoints.up('md')]: {
						paddingBottom: '14rem',
					},
				},
				footerLogo: {
					...props.footerLogoStyle,
					right: '12rem',
				},
				listView: {
					backgroundColor: '#fafafa',
					paddingTop: '3rem',
					paddingBottom: '0',
				},
			},
			AppBubble: {
				rightPosition: {
					'& $content': {
						backgroundColor: props.secondaryColor[500],
					},
					'& $bubbleContent': {
						color: 'white',
					},
				},
			},
			AppCaseContent: {
				chatBubble: {
					maxWidth: '900px',
				},
				sidebar: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				list: {
					listStyleType: 'none',
					paddingLeft: 0,
				},
				body: {
					color: props.primaryColor[500],
				},
			},
			AppCaseHeader: {
				button: {
					backgroundColor: props.primaryPaletteColor[500]
				},
				subheading: {
					color: 'white',
				},
				body: {
					color: 'white',
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						lineHeight: '5.2rem',
					},
				},
			},
			AppCheckQuestion: {
				formControlLabel: {
					fontSize: '1.6rem',
					lineHeight: '2.6rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						linHeight: '3.8rem',
					},
				},
				formControlRoot: {
					minHeight: '8rem',
					[props.breakpoints.up('md')]: {
						minHeight: '10rem',
					},
				},
			},
			AppChoiceQuestion: {
				header: {
					fontWeight: 'bold',
					marginBottom: '7rem',
					fontSize: '3.4rem',
					lineHeight: '5.2rem',
					marginTop: '1.8rem',
					[props.breakpoints.down('xs')]: {
						fontSize: '3rem',
					},
				},
				subHeader: {
					color: props.primaryColor[500],
					fontSize: '1.4rem',
					fontWeight: 'bold',
					letterSpacing: '.4rem',
					lineHeight: '4rem',
					textTransform: 'uppercase',
				},
			},
			AppContentHeader: {
				title: {
					letterSpacing: '0rem',
				},
			},
			AppDashboardHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
					'& $contentColor': {
						color: props.primaryColor[500],
					},
				},
				body: {
					fontSize: '2rem',
					lineHeight: '4.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2.4rem',
						lineHeight: '5rem',
					},
				},
			},
			AppDefaultFrontPage: {
				fontStyle: {
					color: white[500],
				},
				playButton: {
					backgroundColor: props.secondaryColor[500],
				},
			},
			AppElementCard: {
				root: {
					borderRadius: '0',
					backgroundColor: props.primaryColor[500],
				},
				contentColor: {
					color: white[500],
				},
				type: {
					marginBottom: '1rem',
					fontSize: '1.8rem',
					fontWeight: '500',
				},
				body: {},
				bookCheckContent: {
					'& $body': {
						margin: 0,
						color: white[500],
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: '2px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				doneIcon: {
					color: 'white',
				},
			},
			AppElementOverlay: {
				title: {
					textTransform: 'uppercase',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
				},
				secondary: {
					backgroundColor: props.secondaryBackgroundColor[500],
					'& $left': { backgroundColor: props.secondaryBackgroundColor[500] },
					'& $right': { backgroundColor: props.secondaryBackgroundColor[500] },
					'& $top': { backgroundColor: props.secondaryBackgroundColor[500] },
				},
			},
			AppElementsRoot: {
				grid: {
					maxWidth: '1230px',
					margin: '-370px auto 0px',
					marginTop: '-28rem',
					paddingBottom: '40px',
					[props.breakpoints.up('md')]: {
						margin: '-580px auto 0',
						paddingBottom: '80px',
					},
				},
			},
			AppEntryTestCard: {
				doneIcon: {
					color: 'white',
				},
			},
			AppFactcardReferral: {
				referralRoot: {
					backgroundColor: props.primaryColor[500],
					paddingLeft: 0,
					[props.breakpoints.up('md')]: {
						paddingLeft: '10rem',
					},
				},
				image: {
					backgroundColor: 'white',
					width: '7rem',
					height: '7rem',
					position: 'absolute',
					borderRadius: '100%',
					minHeight: '0',
					left: '4rem',
					top: '3rem',
					backgroundImage: `url(${infoIcon})`,
					display: 'none',
					[props.breakpoints.up('md')]: {
						display: 'block',
					},
				},
				multi: {
					'& $image': {
						minHeight: '0',
					},
				},
				imageSrc: {
					display: 'none',
				},
				button: {
					backgroundColor: 'transparent',
					border: '1px solid white',
					fontSize: '2rem',
					padding: '1.9rem 5rem',
				},
				title: {
					color: 'white',
					fontSize: '2.2rem',
					lineHeight: '3.6rem',
					marginBottom: 0,
					fontWeight: 'bold',
				},
				body: {
					color: 'white',
					fontSize: '1.8rem',
					lineHeight: '3.4rem',
				},
			},
			AppFactcardsCard: {
				bookCheckContent: {
					'& $body': {
						margin: 0,
						color: white[500],
					},
					'& $bodytitle': {
						color: white[500],
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: '2px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				backgroundImage: {
					background: `url("${infoIcon}") no-repeat 80% 50%`,
				},
			},
			AppFactCardTitles: {
				titlesRoot: {
					backgroundColor: props.primaryColor[500],
				},
				titleContainer: {
					'&$active': {
						backgroundColor: props.secondaryColor[500],
					},
				},
				active: {
					backgroundColor: props.secondaryColor[500],
					'& $title': {
						color: 'white',
					},
					'& $longArrow': {
						'& .arrow': {
							backgroundColor: 'white',
						},
					},
				},
			},
			AppFirstPage: {
				wrapper: {
					maxWidth: props.contentWidthM,
				},
				titleRoot: {
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
				},
			},
			AppHotspotMapLandscape: {
				subHeader: {
					paddingBottom: '2.4rem',
				}
			},
			AppImpNews: {
				buttonText: {},
				body: {},
				root: {
					'& $buttonText': {
						color: white[500],
					},
				},
				button: {
					'& $rightArrow': {
						color: white[500],
					},
				},
			},
			AppLesson: {
				largeWrapper: {
					maxWidth: '1230px',
					[props.breakpoints.up('md')]: {
						maxWidth: '1230px',
					},
				},
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '8rem 0',
					},
				},
			},
			AppLessonBottom: {
				root: {
					backgroundColor: white[500],
					borderTop: `1px solid ${props.defaultBorderColor[500]}`,
				},
				button: {
					margin: '0 1rem',
					minWidth: '300px',
				},
				backToBoxButton: {
					display: 'none',
				},
				redoQuestionButton: {
					display: 'flex',
				},
				nextQuestionButton: {
					display: 'flex',
				},
			},
			AppLessonHeader: {
				heading: {
					fontWeight: 'bold',
				},
			},
			AppLessonIntroduction: {
				content: {
					color: props.primaryPaletteColor[500],
					fontSize: '2rem',
					lineHeight: '3.6rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2.4rem',
						lineHeight: '4rem',
						'& > :first-child:first-letter': {
							fontWeight: 'bold',
							margin: '0 3rem 3rem 0',
						},
					},
				},
			},
			AppLoadImage: {
				overlay: {
					backgroundColor: 'rgba(63,107,163,0.8)',
				},
			},
			AppLogin: {
				root: {
					display: 'flex',
					flexDirection: 'column',
					[props.breakpoints.up('lg')]: {
						marginTop: '9rem',
					},
				},
				registrationLink: {
					order: 3,
					marginTop: '2rem',
					[props.breakpoints.up('lg')]: {
						marginTop: '3rem',
					},
				},
			},
			appLoginHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
					minHeight: 0,
					[props.breakpoints.up('lg')]: {
						minHeight: '800px',
					},
				},
				content: {
					backgroundImage: 'none',
					position: 'relative',
				},
				visual: {
					display: 'none',
				},
				visualTitle: {
					display: 'block',
					color: 'transparent',
					background: 'url(' + loginBackgroundImage + ')',
					height: '100%',
					maxHeight: '95vh',
					width: '100%',
					backgroundSize: 'contain',
					position: 'absolute',
					bottom: 0,
					left: '50%',
					transform: 'translateX(-50%)',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center bottom',
				},
				headerTitle: {
					backgroundImage: `url(${logo})`,
					color: 'transparent',
					height: '12.6rem',
					width: '100%',
					backgroundPosition: 'center',
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					marginBottom: '0',
					[props.breakpoints.up('lg')]: {
						marginBottom: '5rem',
					},
				},
				footerLogo: {
					...props.footerLogoStyle,
				},
				scrollIndicator: {
					[props.breakpoints.up('lg')]: {
						"& span": {
							borderLeft: '2px solid #FFF',
							borderBottom: '2px solid #FFF',
						}
					}
				}
			},
			AppLogout: {
				root: {
					backgroundColor: 'white',
				},
			},
			AppOrderQuestion: {
				card: {
					borderRadius: '50px',
					padding: '0 28px',
					backgroundColor: props.secondaryBackgroundColor[500],
					color: props.primaryColor[500],
				},
				number: {
					color: props.primaryColor[500],
				},
			},
			AppRadioGroup: {
				formControl: {
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '5rem',
					},
				},
				formControlRoot: {
					minHeight: '8rem',
					[props.breakpoints.up('md')]: {
						minHeight: '10rem',
					},
				},
			},
			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: white[500],
				},
				heading: {
					textAlign: 'center',
				},
			},
			AppRegistrationSuccess: {
				root: {
					backgroundColor: props.primaryColor[500],
				},
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					color: white[500],
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
				paragraph: {
					color: white[500],
				},
				smallParagraph: {
					color: white[500],
				},
			},
			AppResumeButton: {
				body1: {
					color: props.primaryColor[500],
				},
			},
			AppShortText: {
				content: {
					color: props.primaryColor[500],
				},
			},
			AppStatementQuestion: {
				container: {
					'& $quote': {
						[props.breakpoints.up('md')]: {
							fontSize: '4.3rem',
							lineHeight: '6rem',
							fontWeight: 500,
							marginBottom: '6.7rem',
						},
					},
				},
				button: {
					padding: '2rem',
					borderColor: props.primaryColor[500],
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				secondaryColors: {
					'& $contentColor': {
						color: 'white',
					},
					'& $button': {
						backgroundColor: 'white',
						borderColor: 'white',
						color: 'black',
					},
				},
				primaryColors: {
					'& $button': {
						borderColor: 'white',
					},
				},
			},
			AppStatisticsBox: {
				statistics: {
					[props.breakpoints.up('md')]: {
						border: `1px solid ${props.primaryPaletteColor[100]}`,
					},
				},
				specificStatisticItem: {
					border: `1px solid ${props.primaryPaletteColor[100]}`,
					[props.breakpoints.up('md')]: {
						border: 0,
					},
				},
			},
			AppTeacherHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				heading: {
					color: props.defaultTitleColor[500],
				},
				body: {
					color: props.defaultTitleColor[500],
				},
			},
			AppTeacherProgram: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppTeacherReport: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				content: {
					'& $heading': {
						color: props.defaultTitleColor[500],
					},
				},
			},
			AppTestCard: {
				doneIcon: {
					color: 'white',
				},
			},
			AppTitleAndText: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
					'& $content': {
						color: props.primaryPaletteColor[500],
					},
					'& $header': {
						color: props.primaryPaletteColor[500],
						fontSize: '2.4rem',
						lineHeight: '3.7rem',
						'&.smallUnderline': {
							'&:after': {
								backgroundColor: props.primaryPaletteColor[500],
							},
						},
						[props.breakpoints.up('md')]: {
							fontSize: '3rem',
							lineHeight: '4rem',
						},
					},
				},
				secondary: {
					'& $content': {
						color: 'white',
					},
					'& $header': {
						color: 'white',
						'&.smallUnderline': {
							'&:after': {
								backgroundColor: 'white',
							},
						},
					},
				},
				content: {
					color: white[500],
					lineHeight: '3.3rem',
					fontSize: '1.6rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						lineHeight: '3.6rem',
					},
				},
			},
			AppVideoPlayer: {
				playButton: {
					'& $icon': {
						backgroundColor: props.secondaryColor[500],
						color: 'white',
					},
				},
			},
			AppWhatIsWhatItem: {
				item: {
					color: props.primaryColor[500],
				},
				isQuestion: {
					backgroundColor: props.primaryColor[300],
					borderColor: props.primaryColor[400],
					'& $text': {
						color: 'white',
					},
				},
				arrow: {
					borderColor: props.defaultContentColor[500],
					borderWidth: '2px',
					color: props.defaultContentColor[500],
					'&:hover': {
						[props.breakpoints.up('md')]: {
							backgroundColor: 'white',
						},
					},
				},
			},
			AppWidgetContainer: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
			},
			AppWorkformLayout: {
				header: {
					fontSize: '3.4rem',
					lineHeight: '5.2rem',
					[props.breakpoints.down('xs')]: {
						fontSize: '2.4rem',
						lineHeight: '3.7rem',
					},
				},
				subHeader: {
					color: props.primaryColor[500],
					fontSize: '1.4rem',
					fontWeight: 'bold',
					letterSpacing: '.4rem',
					lineHeight: '3.6rem',
					textTransform: 'uppercase',
				},
				secondaryColors: {
					'& $contentColor': {
						color: white[500],
					},
					'& $button': {
						color: white[500],
					},
				},
			},
			AuthenticationCmsAuthenticator: {
				root: {
					backgroundColor: 'white',
				},
			},
			AuthenticationConfirmReset: {
				root: {
					backgroundColor: 'white',
				},
			},
			AuthenticationLoginForm: {
				submit: {
					padding: '2rem',
					backgroundColor: props.secondaryColor[500],
				},
			},
			AuthenticationResetPassword: {
				root: {
					backgroundColor: 'white',
				},
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			ClaimLicense: {
				root: {
					width: '100%',
					height: '100%',
				},
			},
			CoreSnackbarMessage: {
				message: {
					fontSize: '16px',
				},
			},
			FactCardsHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[300],
				},
			},
			HeaderFaq: {
				heading: {
					letterSpacing: '0rem',
				},
			},
			ManakinLoginForm: {
				root: {
					backgroundColor: yellow[500],
				},
			},
			MuiButton: {
				root: {
					zIndex: 1,
					color: 'white',
					height: 'auto',
					padding: '2rem',
					borderRadius: '4rem',
					[props.breakpoints.up('md')]: {
						padding: '3rem',
					},
				},
				contained: {
					boxShadow: 'none',
				},
				containedPrimary: {
					backgroundColor: props.secondaryColor[500],
				},
				label: {
					fontFamily: props.defaultContentFont,
					position: 'relative',
					textTransform: 'none',
					zIndex: 1,
					fontSize: '1.5rem',
					lineHeight: '2rem',
					textDecoration: 'none',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
				outlinedPrimary: {
					border: `2px solid ${props.primaryPaletteColor[500]}`,
					padding: '18px',
					'&:hover': {
						border: `2px solid ${props.primaryPaletteColor[500]}`,
					},
				},
			},
			MuiChip: {
				label: {
					fontSize: '14px',
				},
			},
			MuiDialog: {
				paper: {
					margin: '18px',
					[props.breakpoints.up('md')]: {
						padding: '4.4rem 5rem 1rem',
					},
				},
				paperFullWidth: {
					minHeight: '53rem',
					borderRadius: 0,
				},
			},
			MuiDialogActions: {
				root: {
					justifyContent: 'center',
					flexDirection: 'column',
					[props.breakpoints.up('md')]: {
						margin: 0,
					},
				},
				action: {
					width: '100%',
					padding: '2rem',
				},
			},
			MuiDialogContent: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
				},
			},
			MuiDialogTitle: {
				root: {
					fontSize: '2rem',
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
					'&>h2': {
						marginBottom: '1rem',
						[props.breakpoints.up('md')]: {
							marginBottom: '4rem',
						},
					},
				},
			},
			MuiDrawer: {
				paper: {
					padding: '3rem',
					fontFamily: "'Heebo', sans-serif;",
					fontSize: '1.8rem',
					color: props.mainMenuColor,
					background: props.mainMenuBackground[500],
					[props.breakpoints.up('md')]: {
						padding: '1.8rem 4rem',
						fontSize: '3rem',
					},
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
				},
			},
			MuiFormControlLabel: {
				root: {
					marginRight: '20px',
					width: '40%',
					'@media (min-width: 1400px)': {
						width: 'auto',
						marginRight: '50px',
					},
				},
			},
			MuiFormGroup: {
				root: {
					width: '100%',
					flexDirection: 'row',
				},
			},
			MuiFormHelperText: {
				root: {
					fontSize: '1.25rem',
				},
			},
			MuiFormLabel: {
				root: {
					fontFamily: "'Heebo', sans-serif;",
					fontSize: '2rem',
					color: props.primaryPaletteColor[500],
				},
			},
			MuiInput: {
				input: {
					padding: '14px 0',
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			MuiInputBase: {
				inputMultiline: {
					minHeight: '14rem',
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: '1.6rem',
				},
			},
			MuiPickersDay: {
				daySelected: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
				day: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
			},
			MuiPickersSlideTransition: {
				transitionContainer: {
					'& >*': {
						top: '-6px',
					},
				},
			},
			MuiRadio: {
				checked: {},
				colorPrimary: {
					'&$checked': {
						color: white[500],
					},
				},
				colorSecondary: {
					'&$checked': {
						color: white[500],
					},
				}
			},
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontFamily: props.defaultTitleFont,
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
			MuiSvgIcon: {
				root: {
					width: '2.4rem',
					height: '2.4rem',
				},
			},
			MuiTableCell: {
				root: {
					padding: '4px 16px 4px 16px',
				},
				head: {
					border: 0,
					fontSize: '1.2rem',
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					lineHeight: '2rem',
					letterSpacing: '.4rem',
				},
				body: {
					paddingTop: '1.5rem',
					paddingBottom: '1.5rem',
				},
			},
			MuiTablePagination: {
				caption: {
					fontSize: '1.6rem',
				},
				select: {
					fontSize: '1.6rem',
					lineHeight: '1.6rem',
					paddingRight: 32,
				},
			},
			MuiTypography: {
				root: {
					fontSize: '2rem',
				},
				h1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '3rem',
					fontWeight: 'bold',
					lineHeight: '4.2rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '8rem',
						lineHeight: '10rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '10rem',
						lineHeight: '11rem',
					},
				},
				h2: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.85rem',
					lineHeight: '3.85rem',
					fontWeight: 600,
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					[props.breakpoints.up('sm')]: {
						fontSize: '4.85rem',
						lineHeight: '7rem',
					},
					[props.breakpoints.up('md')]: {
						fontSize: '5rem',
					},
				},
				h3: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.3rem',
					lineHeight: '3.3rem',
					color: props.defaultTitleColor[500],
					fontWeight: 600,
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '3.6rem',
						lineHeight: '4rem',
					},
				},
				h4: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontWeight: 500,
					fontSize: '2.2rem',
					lineHeight: '3rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						lineHeight: '4rem',
						marginBottom: '2rem',
					},
				},
				h5: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '2rem',
					fontWeight: 'bold',
					lineHeight: '4rem',
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '0.5rem',
					},
				},
				h6: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '1.6rem',
					fontWeight: 'bold',
					lineHeight: '2.6rem',
					marginBottom: '1.3rem',
					letterSpacing: '.2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
						lineHeight: '3.2rem',
						marginBottom: '1.6rem',
					},
				},
				subtitle1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.2rem',
					lineHeight: '3.6rem',
					color: props.defaultTitleColor[400],
					marginBottom: '3rem',
				},
				subtitle2: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.4rem',
					lineHeight: '150%',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
					[props.breakpoints.up('md')]: {
						marginBottom: '4rem',
					},
				},
				body1: {
					fontSize: '1.5rem',
					color: props.defaultContentColor[500],
					fontWeight: 400,
					lineHeight: '2em',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						marginBottom: '3rem',
						lineHeight: '3.6rem',
					},
				},
				body2: {
					fontSmoothing: 'auto',
					color: props.defaultContentColor[500],
					fontWeight: 400,
					fontSize: '2rem',
					lineHeight: '3.8rem',
					fontFamily: props.secondaryTitleFont,
					marginBottom: '2rem',
				},
				gutterBottom: {
					marginBottom: '3em',
				},
				title: {
					fontSize: '2rem',
				},
			},
		},
	});

export default theme;
